var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-supervisor supervisor" },
    [
      _c(
        "div",
        { staticClass: "container container--full-screen m-t-20" },
        [
          _c(
            "div",
            { staticClass: "df jc-sb ai-c" },
            [
              _c("SupervisorTitle", {
                attrs: { title: "Правила вызова авто с парковки" },
              }),
              _c("Icon", {
                staticClass: "pointer",
                attrs: {
                  name: "IconSupervisorSwitchGant",
                  width: "85px",
                  height: "32px",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.changeView.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "supervisor-content" },
            [
              _c(
                "div",
                { staticClass: "supervisor-content__main" },
                [
                  _c("Gant", {
                    ref: "gant",
                    attrs: {
                      "clear-active": _vm.clearActive,
                      "is-show": _vm.requestStatus,
                    },
                    on: {
                      commandQuota: _vm.commandQuota,
                      doubleCLikLine: _vm.addQuotaWithStartTime,
                      emitActiveBars: _vm.handleChangeBlock,
                      quotaRows: _vm.quotaRows,
                    },
                  }),
                ],
                1
              ),
              _c("SupervisorBlockEditQuota", {
                attrs: {
                  "is-show": _vm.requestStatus,
                  "active-quota": _vm.activeQuota,
                  rows: _vm.rows,
                },
                on: { close: _vm.removeActiveQuota },
              }),
            ],
            1
          ),
          _c("SupervisorTable"),
        ],
        1
      ),
      _c("DialogApplySupervisorSamples"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }