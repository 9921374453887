<template>
  <div class="container-supervisor supervisor">
    <div class="container container--full-screen m-t-20">
      <div class="df jc-sb ai-c">
        <SupervisorTitle title="Правила вызова авто с парковки" />
        <Icon
          name="IconSupervisorSwitchGant"
          width="85px"
          height="32px"
          class="pointer"
          @click.native="changeView"
        />
      </div>

      <div class="supervisor-content">
        <div class="supervisor-content__main">
          <Gant
            ref="gant"
            :clear-active="clearActive"
            :is-show="requestStatus"
            @commandQuota="commandQuota"
            @doubleCLikLine="addQuotaWithStartTime"
            @emitActiveBars="handleChangeBlock"
            @quotaRows="quotaRows"
          />
        </div>

        <SupervisorBlockEditQuota
          :is-show="requestStatus"
          :active-quota="activeQuota"
          :rows="rows"
          @close="removeActiveQuota"
        />
      </div>

      <SupervisorTable />
    </div>

    <DialogApplySupervisorSamples />
  </div>
</template>

<script>
import {
  DELETE_SUPERVISOR_QUOTA,
  DELETE_SUPERVISOR_QUOTAS_LINE,
  FETCH_SUPERVISOR_QUOTAS_LINES,
  FETCH_SUPERVISOR_STATISTIC_TABLE,
  GET_IS_MOBILE,
  GET_REQUEST_STATUS,
  GET_TERMINAL_CURRENT_ID,
  SET_DRAWER,
  SET_REQUEST_STATUS,
  SET_UPDATE_ACTION,
} from '@/store/actions'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DialogApplySupervisorSamples from '@/views/supervisor/components/dialogs/dialogApplySamples/DialogApplySupervisorSamples.vue'
import Gant from '@/views/supervisor/components/Gant'
import Icon from '@/UI/icon/Icon.vue'
import SupervisorBlockEditQuota from '@/views/supervisor/components/SupervisorBlockEditQuota/SupervisorBlockEditQuota.vue'
import SupervisorTable from '@/views/supervisor/components/supervisor-statistics/SupervisorStatistics.vue'
import SupervisorTitle from '@/views/supervisor/components/SupervisorTitle'

export default {
  name: 'SupervisorGant',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=1200, initial-scale=1',
      },
    ],
  },
  components: {
    Icon,
    Gant,
    SupervisorBlockEditQuota,
    SupervisorTable,
    SupervisorTitle,
    DialogApplySupervisorSamples,
  },
  data() {
    return {
      clearActive: false,
      quotaEditBlock: false,
      activeQuota: [],
      quotaRowsFromHistory: [],
      rows: [],
    }
  },
  computed: {
    ...mapGetters({
      cultureList: GET_CULTURE_FROM_STATE,
      requestStatus: GET_REQUEST_STATUS,
      terminalId: GET_TERMINAL_CURRENT_ID,
      isMobile: GET_IS_MOBILE,
    }),
  },
  watch: {
    terminalId() {
      this.setAction(this.getData)
      this.getData()
    },
    isMobile(value) {
      this.checkMobile(value)
    },
  },
  mounted() {
    this.checkMobile(this.isMobile)
    this.setAction(this.getData)
    this.getData()
    localStorage.setItem('supervisor-page-format', 'AdminSupervisor')
  },
  beforeRouteLeave(to, from, next) {
    const gantControl = this.$refs.gant.$refs.gantControl

    if (gantControl.isShowBTNs) {
      this.$confirm(
        'Вы покидаете страницу, но не сохранили изменения',
        'Внимание',
        {
          confirmButtonText: 'Сохранить изменения',
          cancelButtonText: 'Продолжить без сохранения',
          type: 'warning',
        },
      )
        .then(() => gantControl.handleConfirmQuotas())
        .catch(() => gantControl.handleCancelQuotas())
        .finally(() => next())
    } else {
      next()
    }
  },
  destroyed() {
    this.stopTimer()
    this.setAction(() => ({}))
  },
  methods: {
    ...mapActions({
      getSupervisorStatisticTable: FETCH_SUPERVISOR_STATISTIC_TABLE,
      getSupervisorQuotasLines: FETCH_SUPERVISOR_QUOTAS_LINES,
      postDeleteQuotas: DELETE_SUPERVISOR_QUOTA,
      deleteQuotaLine: DELETE_SUPERVISOR_QUOTAS_LINE,
    }),
    ...mapMutations({
      setAction: SET_UPDATE_ACTION,
      setDrawer: SET_DRAWER,
      setRequestStatus: SET_REQUEST_STATUS,
    }),
    checkMobile(value) {
      if (value) {
        let route =
          this.$route.name === 'AdminSupervisor'
            ? 'AdminSupervisorTable'
            : 'SupervisorTable'

        this.$router.push({ name: route })
      }
    },
    quotaRows(rows) {
      this.quotaRowsFromHistory = []
      rows.forEach(item => this.quotaRowsFromHistory.push(...item.bars))
    },
    commandQuota(command, rows) {
      switch (command.command) {
        case 'addQuota':
          this.addQuota(command.unload_line_id)
          this.rows = rows
          break
        case 'editQuota':
          this.editQuotas(command.unload_line_id)
          this.rows = rows
          break
        case 'deleteQuota':
          this.deleteQuotas(command.unload_line_id)
          break
        case 'deleteLine':
          this.deleteLine(command.unload_line_id)
          break

        default:
          return null
      }
    },
    editQuotas(unload_line_id) {
      this.clearActive = false
      this.activeQuota = []
      this.activeQuota = this.quotaRowsFromHistory.filter(
        item => String(item.unload_line_id) === String(unload_line_id),
      )

      this.setRequestStatus(true)
    },
    deleteQuotas(unload_line_id) {
      this.$confirm('Вы хотите удалить все квоты?', 'Внимание', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отмена',
        type: 'success',
      })
        .then(() => {
          this.postDeleteQuotas({ unload_line_id })

          this.$message({
            type: 'success',
            message: 'Правила вызова удалены',
            center: true,
          })
        })
        .catch(() => {})
        .finally(() => {})
    },
    deleteLine(id) {
      this.$confirm('Вы хотите удалить линию и все квоты?', 'Внимание', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отмена',
        type: 'success',
      })
        .then(() => {
          this.deleteQuotaLine({ id })

          this.$message({
            type: 'success',
            message: 'Правила вызова удалены',
            center: true,
          })
        })
        .catch(() => {})
        .finally(() => {})
    },

    addQuota(unload_line_id) {
      this.clearActive = false
      this.activeQuota = [
        {
          unload_line_id,
        },
      ]
      this.setRequestStatus(true)
    },
    addQuotaWithStartTime(unload_line_id, time, rows) {
      this.clearActive = false
      this.activeQuota = [
        {
          unload_line_id,
          time_from: time.time_from,
          time_to: time.time_to,
        },
      ]
      this.rows = rows
      this.setRequestStatus(true)
    },
    removeActiveQuota() {
      this.clearActive = true
      this.quotaEditBlock = false
      this.activeQuota = []
      this.setRequestStatus(false)
    },
    getData() {
      this.stopTimer()
      this.getSupervisorQuotasLines()
      this.getSupervisorStatisticTable()
      this.startTimer()
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.getSupervisorQuotasLines()
        this.getSupervisorStatisticTable()
      }, 300000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    // new
    handleChangeBlock(event, rows) {
      this.setRequestStatus(true)

      this.rows = rows
      this.activeQuota = event
      this.quotaEditBlock = true
      this.clearActive = false
    },
    changeView() {
      let route =
        this.$route.name === 'Supervisor'
          ? 'SupervisorTable'
          : 'AdminSupervisorTable'

      this.$router.push({ name: route })
    },
  },
}
</script>

<style lang="sass">
.container-supervisor
  padding: 30px $space-12
  min-height: 60px
  display: flex
  justify-content: center
  flex-wrap: wrap

  .el-table__body,
  .el-table__header
    width: 100% !important

  .container
    background: #FFFFFF
    border: $border-default
    box-shadow: $box-shadow-default
    border-radius: 8px

.box-supervisor
  padding: $space-12
  box-shadow: 0 3px 9px rgba(75, 81, 91, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1)
  margin: 0 auto
  width: 1312px
  display: flex
  justify-content: space-between
  @media(max-width: 768px)
    width: 100%
    display: flex
    justify-content: space-between
    flex-direction: column

  .container
    flex: 0 0 100%

.supervisor__title
  display: flex
  justify-content: space-between
  align-items: center

.supervisor-content
  display: flex
  justify-content: flex-start
  align-items: flex-start

  &__main
    flex: 1 1 auto
    min-width: 0
    visibility: visible
    transition: all 1s ease
    &.hide
      flex: 0 0 0
      overflow: hidden
      visibility: hidden
      transition: flex .3s ease .1s, visibility .3s ease .1s

.pointer
  cursor: pointer
</style>
